import React, {useEffect} from 'react'
// import { analyticsLog } from '../services/firebase';

export default function Contact() {
  //   useEffect(() => {
  //   document.title = "Contact"
  //   analyticsLog('Contact')
  // }, [])
  return (
    <div className="contact-wrap">
      <ul>
        <p>
          <span>
            <img alt= "project screenshot" src="https://img.icons8.com/color/48/000000/gmail.png" />
          </span>
          <a href="mail:firaskudsy@gmail.com" target="_blank" rel="noreferrer noopener">firaskudsy@gmail.com</a>
        </p>
        <p>
          <span>
            <img alt= "project screenshot" src="https://img.icons8.com/color/48/000000/linkedin.png" />
          </span>
          <a href="https://www.linkedin.com/in/firaskudsy/" target="_blank" rel="noreferrer noopener">linkedin.com/firaskudsy</a>
        </p>
        <p>
          <span>
            <img alt= "project screenshot" src="https://img.icons8.com/color/48/000000/github.png" />
          </span>
          <a href="https://github.com/firaskudsy" target="_blank" rel="noreferrer noopener">github.com/firaskudsy</a>
        </p>
        <p>
          <span>
            <img alt= "project screenshot" src="https://img.icons8.com/color/48/000000/twitter.png" />
          </span>
          <a href="https://twitter.com/firaskudsy" target="_blank" rel="noreferrer noopener">twitter.com/firaskudsy</a>
        </p>
      </ul>
            <div className="space"></div>

    </div>
  );
}
