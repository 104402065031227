import React, {useEffect} from 'react'
// import { analyticsLog } from '../services/firebase';

export default function Projects() {
  //   useEffect(() => {
  //   document.title = "Projects"
  //   analyticsLog('Projects')
  // }, [])

  return (
    <div className="contents">
      <h2>Projects</h2>
      <div className="wrapper">
        <div className="project">
          <div className="project1"></div>
          <span>
            <h2>
              Fitviser <span>[Angular + Firebase]</span>
            </h2>
            <p>Setup products or services and manage orders, clients, shipping, delivery or pickup all under one platform.</p>
          </span>
        </div>
        <div className="project">
          <div className="project2"></div>
          <span>
            <h2>
              Actionshop <span>[Angular + Loopback + MongoDB]</span>
            </h2>
            <p>Setup products or services and manage orders, clients, shipping, delivery or pickup all under one platform.</p>
          </span>
        </div>
        <div className="project">
          <div className="project3"></div>
          <span>
            <h2>
              Helium-Tools <span>[React + Firebase]</span>
            </h2>
            <p>
              rewards monitorting tool for the Helium blockchain. It is a web application that allows users to view the rewards of their Helium accounts and
              also from other accounts.
            </p>
          </span>
        </div>
        <div className="project">
          <div className="project4"></div>
          <span>
            <h2>
              QE Dashboard <span>[Angular + Node/express + MongoDB]</span>
            </h2>
            <p>monitor and manager coverages and test execution ROI from multiple sources like HP ALM, JIRA and JTMF</p>
          </span>
        </div>
        <div className="project">
          <div className="project4"></div>
          <span>
            <h2>
              QE Daily Reporting <span>[Angular + NestJS + MongoDB]</span>
            </h2>
            <p>reporting tool for test executions, defects and environment downtime from multiple resources like HP ALM, JIRA and JTMF</p>
          </span>
        </div>
        <div className="project">
          <div className="project4"></div>
          <span>
            <h2>
              Scan Code Dashboard <span>[Angular + Node/express + MongoDB]</span>
            </h2>
            <p>manage Dequee scan code JSON report to monitor, followup and take actions on all reported issues</p>
          </span>
        </div>
        <div className="project">
          <div className="project4"></div>
          <span>
            <h2>
              EDR <span>[Angular + Node/express + MongoDB]</span>
            </h2>
            <p>environment downtime report to manage submited downtime reports</p>
          </span>
        </div>
        <div className="project">
          <div className="project4"></div>
          <span>
            <h2>
              SV EasyBuilder <span>[React + NestJS]</span>{' '}
            </h2>

            <p>portal enabling QE teams to easly create virtualized services with simple steps and deply it</p>
          </span>
        </div>
        <div className="project">
          <div className="project4"></div>
          <span>
            <h2>
              SV ROI <span>[React + NestJS + MongoDB + PostgreSQL]</span>{' '}
            </h2>

            <p>dashboard to manage ROI for the multiple SV enviroments</p>
          </span>
        </div>
      </div>

      <div className="space2"></div>
    </div>
  );
}
