import Iframe from 'react-iframe'
import React, {useEffect} from 'react'
// import { analyticsLog } from '../services/firebase';

export default function Resume() {
  //   useEffect(() => {
  //   document.title = "Resume"
  //   analyticsLog('Resume')
  // }, [])


  return <div className="resume-wrapper">
<Iframe url="/Firas-Kudsy.pdf"
        width="90%"
        height="800px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
              <div className="space"></div>

  </div>;
}
