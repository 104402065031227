import {getAnalytics, logEvent} from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import firebaseConfig from '../firebase-config/config'; // import firebase config


// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const analyticsLog = (eventName, params) => {
  logEvent(analytics, eventName, params);
};
