import About from './components/About';
import Experiance from './components/Experiance';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Resume from './components/Resume';
import React, {useEffect} from 'react'
import { analyticsLog } from './services/firebase';

function App() {
    useEffect(() => {
    document.title = "Firas Kudsy - Portfilio"
    analyticsLog('Home')
  }, [])
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  const date = `${month}/${day}/${year}`;
  return (
    <div>
      <div id="tab0">
        <div className="title">
          <div className="name"></div>
          <div className="subtitle"></div>

          {/* <h1>
            <span>Firas Kudsy</span>
          </h1> */}
          <div className="click-here"></div>

        </div>
        <div className="whiteboard">
          <div className="frame"></div>
          <div className="mac"></div>
          <div className="cup"></div>
          <div className="notebook">
            <div>
              <p>To do : {date}</p>
              <p>* complete project B Rest API </p>
              <p>* add the Cart component </p>
              <p>
                * Update MongoDB Price <br />
                Collection{' '}
              </p>
              <p>
                * deploy Auth service to <br /> firebase cloud{' '}
              </p>
              <p>* finish daily learnings :) </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="tab7">
        <Resume />
      </div> */}
      <div id="tab6">
        <Resume />
      </div>

      <div id="tab5">
        <Contact />
      </div>

      <div id="tab4">
        <Skills />
      </div>
      <div id="tab3">
        <Projects />
      </div>
      <div id="tab2">
        <Experiance />
      </div>
      <div id="tab1">
        <About />
      </div>

    </div>
  );
}

export default App;
