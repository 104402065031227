import React, {useEffect} from 'react'
// import { analyticsLog } from '../services/firebase';

export default function About() {
  //   useEffect(() => {
  //   document.title = "About"
  //   analyticsLog('About')
  // }, [])
  return         <div className="contents">
          <h2>About</h2>
          <p>
            A senior full-stack developer with a passion for building great web applications, with over 20 years of proven development skills and proficiency in the latest programming languages and frameworks technologies gained through years of successful implementations. Capable of carrying tasks independently or with minimal supervision. Solutions orientated, performing very well under pressure, achieving deadlines and working within budgets.
          </p>
          <div className="coding">
<img alt="programmer animated gif" src="./coding.gif" />
          </div>
         <div className="space"></div>

        </div>;
}
