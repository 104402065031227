 const firebaseConfig = {
  apiKey: "AIzaSyAJHItlhMBmLcMXoCJi_1hj38RvUd_Qw74",
  authDomain: "firas-kudsy.firebaseapp.com",
  projectId: "firas-kudsy",
  storageBucket: "firas-kudsy.appspot.com",
  messagingSenderId: "979024207195",
  appId: "1:979024207195:web:aea3a17b336e5e46460e7e",
  measurementId: "G-WWGC31RFZX"
};

 export default firebaseConfig;
 